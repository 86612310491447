import React, { Component } from "react";
import { Label, Form, Dropdown, Image, Checkbox, Header, Icon, Segment } from "semantic-ui-react";

import { COUNTRIES, copyToClipboard } from "../../util/common";
import { ROLES } from "../../modules/Users/roles";
import { Can } from "../../modules";
import UserInput from "../UserInput";
import PasswordInput from "../PasswordInput";

import Service from "./service";

import "./LocationEdit.scss";

const BAR_CODE_TYPES = {
  BAR_CODE_128: 1,
  BAR_CODE_93: 2,
  BAR_CODE_39: 3,
  BAR_CODE_25: 4,
  BAR_CODE_11: 5,
};

class LocationEdit extends Component {
  handleInputChange = e => {
    let { location, handleChange } = this.props;
    if (Object.keys(location).length === 0) return;
    location[e.target.name] = e.target.value;
    handleChange(location);
  };

  handleSelectChange = (e, data) => {
    let { location, handleChange } = this.props;
    if (Object.keys(location).length === 0) return;
    location[data.name] = data.value;
    handleChange(location);
  };

  handleFileChange = event => {
    let { location, handleChange } = this.props;
    if (!event || !event.target || !event.target.files) {
      return;
    }
    location.photo = event.target.files[0];
    handleChange(location);
  };

  handleCheckbox = (e, data) => {
    let { location, handleChange } = this.props;
    location[data.name] = data.checked;
    handleChange(location);
  };

  handleIPEnabledCheckbox = (e, data) => {
    let { location, handleChange } = this.props;
    location.ip_address_enabled = data.checked;

    if (!data.checked) {
      location.ip_address = "";
    }

    handleChange(location);
  };

  handleKeylockerCodeChange = (_e, { value }) => {
    const { location, handleChange } = this.props;

    if (value && (value[0] === "0" || isNaN(value) || value.length > 4)) return;

    handleChange({ ...location, keylocker_code: value });
  };

  handleUploadCompanyStamp = e => {
    const { location, handleChange } = this.props;

    const file = e.target.files[0];
    const path = `${location.name}/${new Date().toDateString()}`;

    const formData = new FormData();
    formData.append("path", path);
    formData.append("file", file);

    Service.uploadCompanyStamp(formData)
      .then(res => {
        handleChange({ ...location, company_stamp: res.data.data.URL });
      })
      .catch(err => console.log("Error adding company stamp", err));
  };

  handleCopyToClipboard = value => {
    copyToClipboard(value);
  };

  render() {
    const { location, t, isAdmin, isSubmit, languages, brands, user, dealer } = this.props;

    return (
      location && (
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("location_name").message || "Location name"}</label>
            <UserInput
              disabled={user.role_id > ROLES.SUPPORT_ADMIN}
              value={location.name}
              name="name"
              placeholder={t("location_name").message || "Location name"}
              onChange={this.handleInputChange}
            />
            {isSubmit && !location.name && (
              <Label basic color="red" pointing>
                {t("field_is_required").message || "This field is required."}
              </Label>
            )}
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("location_header").message || "Location header"}</label>
            <UserInput value={location.headline} placeholder={t("location_header").message || "Location header"} name="headline" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("location_footer").message || "Location footer"}</label>
            <UserInput value={location.footerline} placeholder={t("location_footer").message || "Location footer"} name="footerline" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("country").message || "Country"}</label>
            <Dropdown
              placeholder={t("country").message || "Country"}
              search
              options={
                COUNTRIES
                  ? COUNTRIES.map((a, key) => {
                      return { text: a.text, value: a.value, key };
                    })
                  : []
              }
              selection
              name="country"
              value={location.country}
              onChange={this.handleSelectChange}
            />
            {isSubmit && !location.country && (
              <Label basic color="red" pointing>
                {t("field_is_required").message || "This field is required."}
              </Label>
            )}
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("city").message || "City"}</label>
            <UserInput value={location.city} placeholder={t("city").message || "City"} name="city" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("street").message || "Street"}</label>
            <UserInput value={location.street} placeholder={t("street").message || "Street"} name="street" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("postal_code").message || "Postal code"}</label>
            <UserInput value={location.postal_code} placeholder={t("postal_code").message || "Postal code"} name="postal_code" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("phone").message || "Phone"}</label>
            <UserInput value={location.phone_nr} name="phone_nr" placeholder={t("phone").message || "Phone"} onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("language").message || "Language"}</label>
            <Dropdown
              placeholder={t("language").message || "Language"}
              options={
                languages?.languages?.length
                  ? languages.languages.map((a, key) => ({
                      text: a.language_code,
                      value: a.language_code,
                      key,
                    }))
                  : []
              }
              selection
              name="language_code"
              value={location.language_code}
              onChange={this.handleSelectChange}
            />
            {isSubmit && !location.language_code && (
              <Label basic color="red" pointing>
                {t("field_is_required").message || "This field is required."}
              </Label>
            )}
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("commercial_name").message || "Commercial name"}</label>
            <UserInput
              value={location.commercial_name}
              name="commercial_name"
              placeholder={t("commercial_name").message || "Commercial name"}
              onChange={this.handleInputChange}
            />
            {isSubmit && !location.commercial_name && (
              <Label basic color="red" pointing>
                {t("field_is_required").message || "This field is required."}
              </Label>
            )}
          </Form.Field>
          <Can I="upload_logo" the="files">
            <Form.Field className="full-width-input">
              <label>Upload Logo (.jpg, .jpeg, .png)</label>
              <input id="newLocationLogo" className="locationLogoUploader" type="file" onChange={this.handleFileChange} />
              {location.logo && <Image src={location.logo} />}
            </Form.Field>
          </Can>
          <Can I="upload" the="files">
            <Form.Field className="full-width-input">
              <label>{t("upload_company_stamp").message || "Upload Company Stamp"} (.jpg, .jpeg, .png)</label>
              <input id="newLocationLogo" className="locationLogoUploader" type="file" accept="image/*" onChange={this.handleUploadCompanyStamp} />
              {location.company_stamp && <Image src={location.company_stamp} />}
            </Form.Field>
          </Can>
          {isAdmin && (
            <div>
              <Form.Field className="full-width-input">
                <label>{t("brands").message || "Brands"}</label>
                <Dropdown
                  placeholder={t("brands").message || "Brands"}
                  selection
                  fluid
                  search
                  options={
                    brands
                      ? brands.map((a, key) => {
                          return { text: a.name, value: a.id, key };
                        })
                      : []
                  }
                  multiple={true}
                  value={location.brand_ids}
                  name="brand_ids"
                  onChange={this.handleSelectChange}
                />
              </Form.Field>
              <Form.Field className="full-width-input">
                <label>{t("Importer V#").message || "Importer V#"}</label>
                <UserInput
                  defaultValue={location.importer_version}
                  name="importer_version"
                  placeholder={t("Importer V#").message || "Importer V#"}
                  onChange={this.handleInputChange}
                />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("Location key").message || "Location key"}</label>
                <textarea className="full-width-input" defaultValue={location.id} rows="1" cols="100" disabled={true} readOnly="readonly"></textarea>
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("datastore_key").message || "Datastore key"}</label>
                <div className="copy-label">
                  <Icon name="copy outline" onClick={() => this.handleCopyToClipboard(location.datastore_key)}></Icon>
                  <div>{location.datastore_key}</div>
                </div>
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("tire_scanner").message || "Tire Scanner"}</label>
                <Checkbox
                  name="is_tire_scanner_enabled"
                  checked={location.is_tire_scanner_enabled}
                  onChange={this.handleCheckbox}
                  label={t("tire_scanner").message || "Tire Scanner"}
                />
              </Form.Field>

              {location.is_tire_scanner_enabled && (
                <Form.Field className="full-width-input">
                  <label>{t("tire_scanner_private_key").message || "Tire Scanner private key"}</label>
                  <div className="copy-label">
                    <Icon name="copy outline" onClick={() => this.handleCopyToClipboard(location.tire_scanner_private_key)}></Icon>
                    <div>{location.tire_scanner_private_key}</div>
                  </div>
                </Form.Field>
              )}

              <Form.Field className="full-width-input">
                <label>{t("bar_code_type").message || "Bar code type"}</label>
                <Dropdown
                  placeholder={t("bar_code_type").message || "Bar code type"}
                  selection
                  fluid
                  options={Object.keys(BAR_CODE_TYPES).map(type => ({
                    value: BAR_CODE_TYPES[type],
                    key: BAR_CODE_TYPES[type],
                    text: t(type.toLowerCase()).message || type.replace(/_/g, " "),
                  }))}
                  value={location.bar_code_type}
                  name="bar_code_type"
                  onChange={this.handleSelectChange}
                />
              </Form.Field>
            </div>
          )}
          <Segment>
            <Header as="h5">
              <Icon name="settings" />
              <Header.Content>{t("ip_address").message || "IP Address"}</Header.Content>
            </Header>
            <Segment.Inline>
              <Form.Field className="full-width-input">
                <Checkbox toggle checked={location.ip_address_enabled} onChange={this.handleIPEnabledCheckbox} label={t("ip_address").message || "IP Address"} />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("ip_address").message || "IP address"}</label>
                <UserInput
                  disabled={!location.ip_address_enabled}
                  value={location.ip_address}
                  name="ip_address"
                  placeholder={t("ip_address").message || "IP address"}
                  onChange={this.handleInputChange}
                />
              </Form.Field>
            </Segment.Inline>
          </Segment>
          {isAdmin && dealer.dbb_enabled && (
            <div>
              <Segment>
                <Header as="h5">
                  <Icon name="settings" />
                  <Header.Content>DBB</Header.Content>
                </Header>
                <Segment.Inline>
                  <Form.Field className="full-width-input">
                    <Checkbox toggle checked={location.dbb_enabled} name="dbb_enabled" onChange={this.handleCheckbox} label={t("dbb_enabled").message || "DBB Enabled"} />
                  </Form.Field>
                  <Form.Field className="full-width-input">
                    <label>{t("dbb_user").message || "DBB User"}</label>
                    <UserInput value={location.dbb_user} placeholder={t("dbb_user").message || "DBB User"} name="dbb_user" onChange={this.handleInputChange} />
                  </Form.Field>
                  <Form.Field className="full-width-input">
                    <label>{t("dbb_password").message || "DBB Password"}</label>
                    <PasswordInput
                      value={location.dbb_password}
                      placeholder={t("dbb_password").message || "DBB Password"}
                      name="dbb_password"
                      onChange={this.handleInputChange}
                      showAsteriskPlaceholder={location.has_dbb_password}
                    />
                  </Form.Field>
                </Segment.Inline>
              </Segment>
            </div>
          )}

          <Segment>
            <Header as="h5">
              <Icon name="settings" />
              <Header.Content>{t("privacy").message || "Privacy"}</Header.Content>
            </Header>
            <Segment.Inline>
              <Form.Field className="full-width-input">
                <Checkbox
                  toggle
                  name="is_employee_name_visible"
                  checked={location.is_employee_name_visible}
                  onChange={this.handleCheckbox}
                  label={t("show_employees_name").message || "Show employees name"}
                />
              </Form.Field>
            </Segment.Inline>
          </Segment>

          <Segment>
            <Header as="h5">
              <Icon name="key" />
              <Header.Content>{t("keylocker").message || "Keylocker"}</Header.Content>
            </Header>
            <Segment.Inline>
              <Form.Field className="full-width-input">
                <label>{t("tablet_keylocker_security_code").message || "Tablet security code"}</label>
                <UserInput name="keylocker_code" value={location.keylocker_code} onChange={this.handleKeylockerCodeChange} />
                {isSubmit && location.keylocker_code?.length < 4 && (
                  <Label basic color="red" pointing>
                    {t("keylocker_code_validation_error").message || "Keylocker code is required to have 4 digits"}
                  </Label>
                )}
              </Form.Field>
            </Segment.Inline>
          </Segment>
        </Form>
      )
    );
  }
}

export default LocationEdit;
