import React, { Component } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
import { Modal, Button, Icon } from "semantic-ui-react";
import { saveAs } from "file-saver";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltRight } from "@fortawesome/pro-solid-svg-icons";

import { renderBasedOnTemplateAndOptions, getYYYMMDD, isAdmin } from "../../util/common";
import CustomConfirm from "../../components/CustomConfirm";
import { ROLES } from "../Users/roles";
import AppointmentChecklistGroup from "./AppointmentChecklistGroup";
import { deleteAppointmentCheck, APPOINTMENTS_ACTION_TYPES } from "./store";
import { APPOINTMENT_STATUSES } from "./common";

import Service from "./service";

const renderChecklistGroups = ({
  selectedCustomerComm,
  vat,
  includeVAT,
  getTotalPrice,
  checklist,
  sublists,
  onMediaClick,
  onAnswerUpdate,
  onChangeCustomerCommunication,
  appointment,
  dashboardConfig,
  listVisibility,
  isFinalCheckDone,
  isEditable,
  isPriceEditable,
  currentUser,
  isCustomerCommunicationVisible,
  isDeskCommunicationVisible,
  tyreSizes,
  location,
  keyloopEnabled,
}) => {
  let keys = Object.keys(sublists);
  keys.sort();

  let qg = [];
  for (let x = 0; x < keys.length; x++) {
    qg.push(
      <AppointmentChecklistGroup
        vat={vat}
        includeVAT={includeVAT}
        getTotalPrice={getTotalPrice}
        key={x}
        rnd={Date.now()}
        type="checklist"
        checklist={checklist.checklist}
        isFinalCheckDone={isFinalCheckDone}
        template={checklist.checklist.template}
        title={sublists[keys[x]].group_name}
        appointment={appointment}
        dashboardConfig={dashboardConfig}
        listVisibility={listVisibility}
        onMediaClick={onMediaClick}
        onAnswerUpdate={onAnswerUpdate}
        isEditable={isEditable}
        isPriceEditable={isPriceEditable}
        currentUser={currentUser}
        items={sublists[keys[x]].items}
        isCustomerCommunicationVisible={isCustomerCommunicationVisible}
        isDeskCommunicationVisible={isDeskCommunicationVisible}
        selectedCustomerComm={selectedCustomerComm}
        onChangeCustomerCommunication={onChangeCustomerCommunication}
        tyreSizes={tyreSizes}
        location={location}
        keyloopEnabled={keyloopEnabled}
      />
    );
  }

  return qg;
};

class AppointmentChecklist extends Component {
  state = {
    listVisibility: "nok-statuses",
    isReportMessageVisible: false,
    isReportDisplayVisible: false,
    reportMessage: false,
    reportURL: false,
    reportOptions: false,
    isReportLoading: false,
    showDeleteChecklistConfirm: false,
    deleteChecklistError: null,
    isDeletingCheck: false,
    totalPrice: 0,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.actionType === APPOINTMENTS_ACTION_TYPES.DELETE_CHECK) {
      this.setState({
        showDeleteChecklistConfirm: false,
        isDeletingCheck: false,
      });
    }
  }

  downloadChecklistReport = options => {
    if (!options) {
      return;
    }

    this.setState({ isReportDisplayVisible: false, isReportLoading: true }, () => {
      Service.getChecklistReport(options.car_check_id)
        .then(result => {
          saveAs(new Blob([result.data]), "Car_" + this.props.appointment.reg_number_escaped + "_WO_" + this.props.appointment.wo_nr + "_" + getYYYMMDD() + ".pdf");
          this.setState({ isReportLoading: false });
        })
        .catch(error => {
          console.error("Error getting checklist report.", error);
          this.setState({
            isReportMessageVisible: true,
            isReportLoading: false,
            reportMessage:
              this.props.t("could_not_generate_report_message").message || "Could not generate a report. Make sure that this checklist has a report template selected.",
          });
        });
    });
  };

  showChecklistReport = options => {
    if (!options || !options.appointment_id || !options.car_check_id) {
      return;
    }

    if (!options.is_previewable) {
      this.downloadChecklistReport(options);
      return;
    }

    this.setState({ isReportLoading: true }, () => {
      Service.getChecklistReportUrl(options.car_check_id)
        .then(result => {
          if (result) {
            this.setState(
              {
                isReportDisplayVisible: true,
                reportOptions: options,
                reportURL: result.data.data.url,
              },
              () => {
                this.loadReportHtml();
              }
            );
          }

          this.setState({ isReportLoading: false });
        })
        .catch(error => {
          console.error("Error getting checklist report.", error);
          this.setState({
            isReportMessageVisible: true,
            isReportLoading: false,
            reportMessage:
              this.props.t("could_not_generate_report_message").message || "Could not generate a report. Make sure that this checklist has a report template selected.",
          });
        });
    });
  };

  toggleListVisibility = () => {
    let listVisibility = this.state.listVisibility === "all" ? "nok-statuses" : "all";

    this.setState({ listVisibility });
  };

  handleHideReportMessage = () => {
    this.setState({ isReportMessageVisible: false });
  };

  handleHideReportDisplay = () => {
    this.setState({ isReportDisplayVisible: false });
  };

  renderReportMessage = () => {
    if (!this.state.isReportMessageVisible) {
      return "";
    }

    const { t } = this.props;

    return (
      <Modal
        className="ReportMessageModal"
        size="mini"
        dimmer="blurring"
        open={this.state.isReportMessageVisible}
        onClose={this.handleHideReportMessage}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Icon name="file pdf outline" />
          {t("could_not_get_report_header").message || "Could not get the report"}
        </Modal.Header>
        <Modal.Content>{this.state.reportMessage}</Modal.Content>
        <Modal.Actions
          style={{
            textAlign: "center",
          }}
        >
          <Button color="blue" onClick={this.handleHideReportMessage}>
            {this.props.t("close").message || "CLOSE"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  loadReportHtml = () => {
    this.iframe.src = this.state.reportURL;
  };

  handleIframe = component => {
    this.iframe = component;
  };

  printIframe = () => {
    try {
      this.iframe.contentWindow.postMessage("print", "*");
    } catch (e) {}
  };

  renderReportDisplay = () => {
    let isIE = window.navigator.userAgent.indexOf("MSIE") > 0 || window.navigator.userAgent.indexOf("Trident") > 0;
    return (
      <Modal
        className="PrintPreviewModal"
        closeOnEscape
        size="large"
        dimmer="blurring"
        open={this.state.isReportDisplayVisible}
        onClose={() => this.setState({ isReportDisplayVisible: false })}
        closeOnDimmerClick={false}
      >
        <Modal.Actions
          style={{
            textAlign: "right",
            position: "relative",
            zIndex: 1000,
          }}
        >
          {!isIE && (
            <Button color="green" onClick={() => this.printIframe()}>
              {this.props.t("print").message || "PRINT"}
            </Button>
          )}
          <Button color="green" onClick={() => this.downloadChecklistReport(this.state.reportOptions)}>
            {this.props.t("download").message || "DOWNLOAD"}
          </Button>
          <Button color="blue" onClick={this.handleHideReportDisplay}>
            {this.props.t("close").message || "CLOSE"}
          </Button>
        </Modal.Actions>
        <Modal.Content>
          <iframe style={{ width: "100%", minHeight: "600px", border: "0px" }} ref={this.handleIframe} title="reportDisplay"></iframe>
        </Modal.Content>
      </Modal>
    );
  };

  handleShowDeleteChecklistConfirm = () => {
    this.setState({
      showDeleteChecklistConfirm: true,
    });
  };

  handleCancleDeleteChecklistConfirm = () => {
    this.setState({
      showDeleteChecklistConfirm: false,
    });
  };

  renderDeleteChecklistConfirm = () => {
    const { showDeleteChecklistConfirm, deleteChecklistError, isDeletingCheck } = this.state;
    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteChecklistConfirm}
        confirmMsg={this.props.t("confirm_delete_check").message || "Are you sure that you want to delete this? You can't undo this action."}
        error={deleteChecklistError}
        handleCancel={this.handleCancleDeleteChecklistConfirm}
        handleConfirm={this.handleDeleteChecklist}
        isLoading={isDeletingCheck}
      />
    );
  };

  handleDeleteChecklist = () => {
    const { appointment_id, id } = this.props.checklist;
    this.setState(
      {
        isDeletingCheck: true,
      },
      () => {
        Service.deleteChecklist(appointment_id)
          .then(response => {
            this.props.deleteAppointmentCheck(id);
            this.setState({
              isDeletingCheck: false,
            });
          })
          .catch(err => {
            this.setState({ deleteChecklistError: err.response.data.errors[0], isDeletingCheck: false });
            console.error("Error getting deleting checks.", err);
          });
      }
    );
  };

  render() {
    const { showDeleteChecklistConfirm } = this.state;
    const { checklist } = this.props;
    const { lastCheck } = checklist;
    let {
      onMediaClick,
      onAnswerUpdate,
      onChangeCustomerCommunication,
      appointment,
      dashboardConfig,
      isFinalCheckDone,
      isEditable,
      isPriceEditable,
      currentUser,
      isCustomerCommunicationVisible,
      isDeskCommunicationVisible,
      selectedCustomerComm,
    } = this.props;
    let template = checklist.checklist.template;
    let templateStyle = {};
    let iconColor = "";
    if (template) {
      templateStyle = {
        backgroundColor: template.primary_color,
        color: template.text_color,
      };
      iconColor = template.icon_color;
    }

    return (
      <div key={`ac-${checklist.id}`} className={`AppointmentChecklist -list-visibility-${this.state.listVisibility}`} id={`check-scroll-id-${checklist._check_index}`}>
        <div className="AppointmentLists__list-title" style={templateStyle}>
          {template && template["hide_timestamp"] ? (
            <h1>
              {checklist.checklist.name}
              {checklist.purpose !== "" && <span> - {checklist.purpose}</span>}
            </h1>
          ) : (
            <h1>
              {`#${checklist._check_index} - ${checklist.checklist.name} - `}
              {checklist.purpose !== "" && <span>{checklist.purpose} - </span>}
              <span className="-margin-right-5">
                {`${checklist.mechanic.first_name} ${checklist.mechanic.last_name} - ${moment(checklist.created_on)
                  .subtract(checklist.duration, "seconds")
                  .format("HH:mm DD-MM-YY")}`}
              </span>
              <FontAwesomeIcon icon={faArrowAltRight} />
              <span className="-margin-left-5">{`${moment(checklist.created_on).format("HH:mm DD-MM-YY")}`}</span>
            </h1>
          )}

          <div className="-pull-right" style={{ color: iconColor }}>
            {(isAdmin(this.props.currentUser.role_id) || this.props.currentUser.role_id === ROLES.DEALER_ADMIN) &&
              lastCheck &&
              appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_CHECK &&
              !isCustomerCommunicationVisible && <Icon name="trash alternate outline" onClick={() => this.handleShowDeleteChecklistConfirm()} />}

            {!isCustomerCommunicationVisible &&
              renderBasedOnTemplateAndOptions({
                template,
                item: "total_pdf_enabled",
                toRender: (
                  <Icon
                    name="file pdf outline"
                    onClick={() =>
                      this.showChecklistReport({
                        appointment_id: appointment.id,
                        car_check_id: checklist.id,
                        locale: localStorage.getItem("locale"),
                        is_previewable: template?.is_previewable,
                      })
                    }
                  />
                ),
              })}

            {this.state.isReportLoading && (
              <div className="Loader">
                <Loader type="Oval" color="#46923d" height="100" width="100" />
              </div>
            )}
            <Icon name="bars" onClick={this.toggleListVisibility} />
          </div>
        </div>

        {renderChecklistGroups({
          selectedCustomerComm,
          vat: this.props.location.vat,
          includeVAT: this.props.location.include_vat,
          getTotalPrice: this.props.getTotalPrice,
          checklist,
          sublists: checklist.sublists,
          onMediaClick,
          onAnswerUpdate,
          onChangeCustomerCommunication,
          appointment,
          dashboardConfig,
          listVisibility: this.state.listVisibility,
          isFinalCheckDone,
          isEditable,
          isPriceEditable,
          currentUser,
          isCustomerCommunicationVisible,
          isDeskCommunicationVisible,
          tyreSizes: this.props.tyreSizes,
          location: this.props.location,
          keyloopEnabled: this.props.keyloopEnabled,
        })}

        {this.renderReportMessage()}
        {this.renderReportDisplay()}
        {showDeleteChecklistConfirm && this.renderDeleteChecklistConfirm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    actionType: state.appointments.actionType,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteAppointmentCheck: checkID => dispatch(deleteAppointmentCheck(checkID)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AppointmentChecklist));
