import React from "react";
import _ from "lodash";
import moment from "moment";
import i18n from "i18next";
import { Label } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUserHardHat, faFileAlt, faDollarSign, faPercentage, faCogs, faKey, faBars } from "@fortawesome/pro-solid-svg-icons";

import { NOTIFICATION_ENTITIES } from "../Auth/util";
import { CHECKLIST_TYPE } from "../Checklists/enum";
import {
  COMMUNICATION_EVENTS,
  COMMUNICATION_STATUSES,
  APPOINTMENT_STATUSES,
  CHANNELS,
  INTERVENTION_ELEMENT_TYPE,
  DESK_COMMUNICATION_EVENTS,
  DESK_COMMUNICATION_STATUSES,
  KEYLOCKER_COMMUNICATION_EVENTS,
  KEYLOCKER_COMMUNICATION_STATUSES,
  KIOSK_COMMUNICATION_EVENTS,
  WO_FILTERS,
} from "./common";

export const APPOINTMENT_NOTE_TYPES = {
  WO: 1,
  MAIN: 2,
  CALL_CUSTOMER: 3,
  BACK_ORDER: 4,
  INFO: 5,
  ATTACHMENT: 6,
  BILL_NOTE: 7,
  TIRESCANNER: 8,
  TEMPORARY_DRIVER_NOTE: 9,
  RECURRING_CAR: 10,
  PARKING: 11,
  DMS: 12,
};

export const isEmpty = object => (object && Object.keys(object).length === 0) || object === undefined || object === "undefined";

export const filterArrayByValue = (coll, value) => _.filter(coll, _.flow(_.values, _.partialRight(_.some, _.method("match", new RegExp(value, "i")))));

export const filterAppointments = (appointments, filters) => {
  if (!appointments) {
    console.warn("Trying to filter empty appointments list");
    return [];
  }

  let visibleAppointments = appointments;

  if (filters.deselectedStatuses?.length > 0) {
    visibleAppointments = visibleAppointments.filter(a => {
      let isVisible = true;

      for (const status of filters.deselectedStatuses) {
        if (
          a.appointment_status_identifier === status ||
          (status === APPOINTMENT_STATUSES.CAR_OUT_OF_SHOP && a.car_out_of_shop) ||
          (status === APPOINTMENT_STATUSES.CAR_READY && a.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW) ||
          (status === APPOINTMENT_STATUSES.QUALITY_CHECK && a.appointment_status_identifier === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW) ||
          (status === APPOINTMENT_STATUSES.HANDLE_CHECK_IN && a.appointment_status_identifier === APPOINTMENT_STATUSES.CHECK_IN_DONE)
        ) {
          isVisible = false;
          break;
        }
      }

      return isVisible;
    });
  }

  if (filters.selectedIcons && filters.selectedIcons.length > 0) {
    let onlyWithoutMoney = filters.selectedIcons.length === 1 && filters.selectedIcons[0] === "is_money";
    visibleAppointments = visibleAppointments.filter(a => {
      let visible = onlyWithoutMoney;
      for (const icon of filters.selectedIcons) {
        switch (icon) {
          case "is_money":
            if (a["is_money"]) return false; // take over if enabled
            break;

          case "is_pin":
            if (a.warranty_pin_count > 0 || a.recall_pin_count > 0 || a.remark_pin_count > 0) visible = true;
            break;

          case "is_shop_green":
            if (a.is_shop_color === "green") visible = true;
            break;

          case "is_shop_orange":
            if (a.is_shop_color === "#f2711c") visible = true;
            break;

          case "is_shop_blue":
            if (a.is_shop_color === "blue") visible = true;
            break;

          case "is_shop_red":
            if (a.is_shop_color === "red") visible = true;
            break;

          case "is_shop_yellow":
            if (a.is_shop_color === "yellow") visible = true;
            break;

          case "is_recurring":
            if (a.is_recurring || a.notes?.some(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.RECURRING_CAR)) visible = true;
            break;

          default:
            if (a[icon]) visible = true;
            break;
        }
      }

      return visible;
    });
  }

  if (filters.searchTerm) {
    const TERM = filters.searchTerm.toUpperCase();

    visibleAppointments = filterArrayByValue(visibleAppointments, TERM);
  }

  if (filters.carMakes && filters.carMakes.length > 0) {
    let term = filters.carMakes.map(item => item.toUpperCase());
    visibleAppointments = visibleAppointments.filter(a => {
      return a.car_make && a.car_make.length > 0 && term.indexOf(a.car_make.toUpperCase()) !== -1;
    });
  }

  if (filters.woFilterSelected !== WO_FILTERS.ALL) {
    const showInternal = filters.woFilterSelected === WO_FILTERS.INTERNAL;
    visibleAppointments = visibleAppointments.filter(a => !!a.internal === showInternal);
  }

  return visibleAppointments;
};

export const sortAppointmentsByStatus = (appointments, statuses) => {
  let orders = [];
  if (statuses) {
    statuses.forEach(s => (orders[s.identifier] = s.order));
  }
  appointments.forEach(a => (a.appointment_status_order = orders[a.appointment_status_identifier]));

  appointments.sort((a, b) => {
    if (a.appointment_status_order < b.appointment_status_order) return -1;
    if (a.appointment_status_order > b.appointment_status_order) return 1;

    if (moment(a.last_timestamp).format("HH:mm A") === moment(b.last_timestamp).format("HH:mm A")) {
      return 0;
    } else {
      return moment(a.last_timestamp).format("HH:mm A") > moment(b.last_timestamp).format("HH:mm A") ? -1 : 1;
    }
  });

  return appointments;
};

export const updateAppointmentStatusIdentifier = appointment => {
  if (appointment.check_in_com_to_handle && appointment.appointment_status_identifier === APPOINTMENT_STATUSES.NEW_CAR) {
    appointment.appointment_status_identifier = APPOINTMENT_STATUSES.HANDLE_CHECK_IN;
    return true;
  } else if (appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_IN_SHOP && appointment.car_out_of_shop) {
    appointment.appointment_status_identifier = APPOINTMENT_STATUSES.NEW_CAR;
    return true;
  } else if (
    [APPOINTMENT_STATUSES.NEW_CAR, APPOINTMENT_STATUSES.CHECK_IN_DONE].includes(appointment.appointment_status_identifier) &&
    appointment.car_in_shop &&
    moment(appointment.car_in_shop).isBefore(moment()) &&
    (!appointment.car_out_of_shop || moment(appointment.car_out_of_shop).isAfter(moment()))
  ) {
    appointment.appointment_status_identifier = APPOINTMENT_STATUSES.CAR_IN_SHOP;
    return true;
  } else if (
    [APPOINTMENT_STATUSES.NEW_CAR, APPOINTMENT_STATUSES.HANDLE_CHECK_IN].includes(appointment.appointment_status_identifier) &&
    appointment.check_in_com_answered &&
    !appointment.check_in_com_to_handle
  ) {
    appointment.appointment_status_identifier = APPOINTMENT_STATUSES.CHECK_IN_DONE;
    return true;
  } else if (
    appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_READY &&
    (appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT || appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED)
  ) {
    appointment.appointment_status_identifier = APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW;
    return true;
  } else if (
    appointment.appointment_status_identifier === APPOINTMENT_STATUSES.QUALITY_CHECK &&
    (appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT || appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED)
  ) {
    appointment.appointment_status_identifier = APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW;
    return true;
  }

  return false;
};

export const sortAppointments = (appointments, statuses) => {
  let panics = [],
    lockers = [],
    others = [];

  appointments.forEach(appointment => {
    if (appointment.has_panic) panics.push(appointment);
    else if (
      (appointment.key_dropped_at && !appointment.key_picked_up_at) ||
      (appointment.sharebox_key_dropped_at && !appointment.sharebox_key_picked_up_at) ||
      (appointment.acses_key_dropped_at && !appointment.acses_key_picked_up_at) ||
      (appointment.kiosk_checked_in_at && !appointment.car_in_shop)
    )
      lockers.push(appointment);
    else if (
      (appointment.key_dropped_back_at && !appointment.key_picked_up_back_at) ||
      (appointment.sharebox_key_dropped_back_at && !appointment.sharebox_key_picked_up_back_at) ||
      (appointment.acses_key_dropped_back_at && !appointment.acses_key_picked_up_back_at)
    )
      lockers.push(appointment);
    else others.push(appointment);
  });

  panics = sortAppointmentsByStatus(panics, statuses);
  lockers = sortAppointmentsByStatus(lockers, statuses);
  others = sortAppointmentsByStatus(others, statuses);

  return [...panics, ...lockers, ...others];
};

// Apply the right update to the appointment and return the updated appointment
export const applyUpdate = (appointment, updatePayload, user, notificationElements) => {
  if (!appointment.interventions) appointment.interventions = [];
  const update = updatePayload.body.update;
  let statusUpdated = false;

  if (updatePayload._topic === "AppointmentStatusUpdatedMessage") {
    if (Array.isArray(appointment.status_history))
      appointment.status_history = appointment.status_history.map(ash => (ash.id === update.id ? { ...ash, ...update } : ash));
  } else if (
    updatePayload._topic === "AppointmentUpdatedMessage" ||
    updatePayload._topic === "InitiatedCheckMessage" ||
    updatePayload._topic === "AppointmentCheckUpdatedMessage" ||
    updatePayload._topic === "CheckPausedUpdatedMessage" ||
    updatePayload._topic === "CarInOutOfShopMessage"
  ) {
    if (user && notificationElements && updatePayload.user_id !== user.id) {
      if (update.appointment_status)
        notifyUser(
          appointment,
          user.notification_element_ids || [],
          NOTIFICATION_ENTITIES.NOTIFICATION_ENTITY_STATUS_IDENTIFIER,
          update.appointment_status.identifier,
          appointment.appointment_status_identifier,
          notificationElements
        );
      else notifyUser(appointment, user.notification_element_ids || [], NOTIFICATION_ENTITIES.NOTIFICATION_ENTITY_APPOINTMENT, update, appointment, notificationElements);
    }

    for (const field in update) {
      if (field === "check_deleted") {
        if (appointment.checklists && appointment.checklists.length > 0) {
          let index = appointment.checklists.findIndex(c => c.id === update[field]);
          if (index > -1) appointment.checklists.splice(index, 1);
        }
      } else {
        appointment[field] = update[field];

        if (field === "appointment_status") {
          const identifier = update[field].identifier;
          appointment.appointment_status_id = update[field].id;
          appointment.appointment_status_color = update[field].color;
          appointment.appointment_status_name = update[field].name;
          appointment.appointment_status_identifier = identifier;
          appointment.appointment_status_order = update[field].order;

          if (update[field].last_user) appointment.last_user = update[field].last_user;

          if (update.has_panic) appointment.last_user = update.last_user;

          if (update[field].timestamp) {
            appointment.last_timestamp = update[field].timestamp;
          }

          if (!appointment.status_history) {
            appointment.status_history = [];
          }

          appointment.status_history.push(update[field]);
          statusUpdated = true;
        }
      }
    }

    statusUpdated = updateAppointmentStatusIdentifier(appointment) || statusUpdated;

    return statusUpdated;
  } else if (updatePayload._topic === "CustomcomUpdatedMessage") {
    if (!appointment.communication_events) appointment.communication_events = [];

    const type = update.type;
    if (type) {
      const event_id = update.event_id || false;
      let receivers = update.receivers || [];
      let customcom_receptionist_key = update.receptionist_key || "";
      let communicationEvent = event_id && appointment.communication_events.find(item => item.id === event_id);

      if (!communicationEvent) {
        let created_on = updatePayload._timestamp;
        let customer = update.customer || null;
        let user = update.user || null;
        let ip = update.ip || null;
        let customer_name = update.customer_name || null;
        let intervention_results = update.intervention_results || null;
        let diagnose_overview_agreed_results = update.diagnose_overview_agreed_results || null;
        let diagnose_overview_declined_results = update.diagnose_overview_declined_results || null;
        let diagnose_overview_contact_results = update.diagnose_overview_contact_results || null;
        let agreements = update.agreeements || null;
        let is_parking_gate_code_sent = !!update.is_parking_gate_code_sent;

        switch (type) {
          case COMMUNICATION_EVENTS.ONLINE_CHECKIN_SENT:
            appointment.customcom_status = COMMUNICATION_STATUSES.ONLINE_CHECKIN_SENT;
            appointment.customcom_customer_id = customer?.id ? customer.id : null;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              customer,
              receivers,
              created_on,
            });
            break;

          case COMMUNICATION_EVENTS.ONLINE_CHECKIN_OPENED:
            appointment.customcom_status = COMMUNICATION_STATUSES.ONLINE_CHECKIN_OPENED;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              created_on,
              intervention_results,
              agreements,
            });
            break;

          case COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED:
            appointment.customcom_status = COMMUNICATION_STATUSES.ONLINE_CHECKIN_ANSWERED;
            appointment.customcom_receptionist_key = customcom_receptionist_key;
            appointment.check_in_com_answered = true;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              ip,
              customer_name,
              created_on,
              receivers,
              is_parking_gate_code_sent,
            });
            break;

          case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT:
            appointment.customcom_status = COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT;
            appointment.customcom_customer_id = customer?.id ? customer.id : null;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              user,
              customer,
              receivers,
              created_on,
            });
            break;

          case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_OPENED:
            appointment.customcom_status = COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_OPENED;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              created_on,
              intervention_results,
              agreements,
            });
            break;

          case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED:
            appointment.customcom_status = COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED;
            appointment.customcom_receptionist_key = customcom_receptionist_key;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              ip,
              customer_name,
              receivers,
              created_on,
              diagnose_overview_agreed_results,
              diagnose_overview_declined_results,
              diagnose_overview_contact_results,
            });
            break;

          case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT:
            appointment.customcom_status = COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT;
            appointment.customcom_customer_id = customer?.id ? customer.id : null;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              user,
              customer,
              receivers,
              created_on,
            });
            break;

          case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT:
            appointment.customcom_status = COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT;
            appointment.customcom_customer_id = customer?.id ? customer.id : null;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              user,
              customer,
              receivers,
              created_on,
            });
            break;

          case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_RESENT:
            appointment.customcom_status = COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT;
            appointment.customcom_customer_id = customer?.id ? customer.id : null;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              user,
              customer,
              receivers,
              created_on,
            });
            break;

          case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_OPENED:
            appointment.customcom_status = COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED;
            appointment.customcom_customer_id = customer?.id ? customer.id : null;
            appointment.communication_events.push({
              id: event_id,
              type: type,
              created_on,
            });
            break;

          default:
            console.log("wrong customer communication status");
        }
      } else if (Array.isArray(receivers)) {
        receivers.forEach(r => {
          let receiver = communicationEvent.receivers?.find(r2 => (r.channel === CHANNELS.WEBHOOK ? r.id === r2.id : r.channel === r2.channel));
          if (receiver) receiver.status = r.status;
        });
      }
    }

    if (typeof update.note !== "undefined") appointment.communication_note = update.note;

    if (typeof update.correct_phone !== "undefined") appointment.customcom_correct_phone = update.correct_phone;

    if (typeof update.correct_email !== "undefined") appointment.customcom_correct_email = update.correct_email;

    if (Array.isArray(update.check_in_remarks)) {
      appointment.check_in_remarks = update.check_in_remarks;
    }

    if (update.check_in_remark && Array.isArray(appointment.check_in_remarks)) {
      const idx = appointment.check_in_remarks.findIndex(remark => remark.id === update.check_in_remark.id);
      if (idx > -1) appointment.check_in_remarks[idx] = { ...appointment.check_in_remarks[idx], ...update.check_in_remark };
    }

    if (Array.isArray(update.diagnose_overview_remarks)) {
      appointment.diagnose_overview_remarks = update.diagnose_overview_remarks;
    }

    if (update.diagnose_overview_remark && Array.isArray(appointment.diagnose_overview_remarks)) {
      const idx = appointment.diagnose_overview_remarks.findIndex(remark => remark.id === update.diagnose_overview_remark.id);
      if (idx > -1) appointment.diagnose_overview_remarks[idx] = { ...appointment.diagnose_overview_remarks[idx], ...update.diagnose_overview_remark };
    }

    if (Array.isArray(update.check_in_results)) {
      appointment.check_in_results = update.check_in_results;
    }

    if (update.check_in_result && Array.isArray(appointment.check_in_results)) {
      const idx = appointment.check_in_results.findIndex(service => service.id === update.check_in_result.id);
      if (idx > -1) appointment.check_in_results[idx] = { ...appointment.check_in_results[idx], ...update.check_in_result };
    }

    if (Array.isArray(update.diagnose_overview_results)) {
      let toUpdate = appointment.diagnose_overview_results ?? [];
      if (appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT || appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED)
        toUpdate = appointment.repair_overview_results ?? [];

      if (toUpdate.length < 1) toUpdate = update.diagnose_overview_results;
      else {
        update.diagnose_overview_results.forEach((r, ri) => {
          let res = toUpdate.findIndex(tu => tu.question_result_id === r.question_result_id);
          if (res > -1) toUpdate[res] = { ...toUpdate[res], ...update.diagnose_overview_results[ri] };
          else toUpdate.push(update.diagnose_overview_results[ri]);
        });

        if (toUpdate.length > update.diagnose_overview_results.length) {
          toUpdate = toUpdate.filter(tu => update.diagnose_overview_results.some(cr => cr.question_result_id === tu.question_result_id));
        }
      }

      if (appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT || appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED)
        appointment.repair_overview_results = [...toUpdate];
      else appointment.diagnose_overview_results = [...toUpdate];
    }

    statusUpdated = updateAppointmentStatusIdentifier(appointment);
    return statusUpdated;
  } else if (updatePayload._topic === "DeskcomUpdatedMessage") {
    if (!appointment.desk_communications) appointment.desk_communications = [];

    const { type, desk_communication_id } = update;

    if (!type) return false;

    const communication = appointment.desk_communications?.find(dc => dc.id === desk_communication_id);

    if (!communication && type !== DESK_COMMUNICATION_EVENTS.CHECKIN_SENT) return false;

    const { event_id } = update;

    let event = communication?.events.find(c => c.id === event_id);

    if (event) return false;

    const {
      user = null,
      customer = null,
      customer_name = null,
      customer_signature = "",
      agreed_interventions = [],
      declined_interventions = [],
      results = [],
      agreements = [],
      receptionist_key = "",
      is_checking_out = false,
    } = update;
    const created_on = updatePayload._timestamp;

    let newCommunication = null;

    switch (type) {
      case DESK_COMMUNICATION_EVENTS.CHECKIN_SENT:
        newCommunication = {
          id: desk_communication_id,
          user: user,
          customer: customer,
          agreements: [],
          declined_interventions,
          agreed_interventions,
          results,
          status: DESK_COMMUNICATION_STATUSES.CHECKIN_SENT,
          is_checking_out,
          created_on,
          receptionist_key,
          events: [{ id: event_id, type, created_on }],
        };

        break;

      case DESK_COMMUNICATION_EVENTS.CHECKIN_RECEIVED:
        newCommunication = {
          ...communication,
          status: DESK_COMMUNICATION_STATUSES.CHECKIN_RECEIVED,
          events: communication.events.concat({
            id: event_id,
            type: type,
            created_on,
          }),
        };

        break;

      case DESK_COMMUNICATION_EVENTS.CHECKIN_FAILED_TO_OPEN:
        newCommunication = {
          ...communication,
          status: DESK_COMMUNICATION_STATUSES.CHECKIN_FAILED_TO_OPEN,
          events: communication.events.concat({
            id: event_id,
            type: type,
            created_on,
          }),
        };

        break;

      case DESK_COMMUNICATION_EVENTS.CHECKIN_OPENED:
        newCommunication = {
          ...communication,
          status: DESK_COMMUNICATION_STATUSES.CHECKIN_OPENED,
          events: communication.events.concat({
            id: event_id,
            type: type,
            created_on,
          }),
        };

        break;

      case DESK_COMMUNICATION_EVENTS.CHECKIN_CANCELED:
        newCommunication = {
          ...communication,
          status: DESK_COMMUNICATION_STATUSES.CHECKIN_CLOSED,
          events: communication.events.concat({
            id: event_id,
            type: type,
            created_on,
          }),
        };

        break;

      case DESK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED:
        appointment.check_in_com_answered = true;

        newCommunication = {
          ...communication,
          receptionist_key: receptionist_key,
          agreed_interventions: agreed_interventions,
          declined_interventions: declined_interventions,
          agreements: agreements,
          results: results,
          status: DESK_COMMUNICATION_STATUSES.CHECKIN_ANSWERED,
          events: communication.events.concat({
            id: event_id,
            type: type,
            created_on,
            customer_name,
            customer_signature,
          }),
        };

        break;

      default:
        return false;
    }

    if (appointment.desk_communications?.length) {
      appointment.desk_communications = appointment.desk_communications.filter(dc => dc.id !== newCommunication.id);
    }

    appointment.desk_communications = [...appointment.desk_communications, newCommunication];

    return false;
  } else if (updatePayload._topic === "KioskComUpdatedMessage") {
    if (!appointment.kiosk_communications) appointment.kiosk_communications = [];

    const { type, remark = null, kiosk_communication_id } = update;

    if (!kiosk_communication_id && remark) {
      appointment.kiosk_communications = appointment.kiosk_communications.map(comm => (comm.id === remark.kiosk_communication_id ? { ...comm, remark } : comm));
      return false;
    }

    if (!type) return false;
    const { event_id } = update;

    const communication = appointment.kiosk_communications?.find(dc => dc.id === kiosk_communication_id) || {
      id: kiosk_communication_id,
      created_on: updatePayload._timestamp,
      events: [],
    };

    let event = communication?.events.find(c => c.id === event_id);

    if (event) return false;

    const { correct_phone = "", customer = null, agreed_interventions = [], checkin_agreements = [], kiosk_name = "", kiosk_label_number = 0, user = null } = update;
    const created_on = updatePayload._timestamp;

    let newCommunication = null;

    switch (type) {
      case KIOSK_COMMUNICATION_EVENTS.CHECKIN_OPENED:
        newCommunication = {
          id: kiosk_communication_id,
          agreed_interventions,
          customer: customer,
          status: KIOSK_COMMUNICATION_EVENTS.CHECKIN_OPENED,
          created_on,
          events: [{ id: event_id, type, created_on }],
        };

        break;

      case KIOSK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED:
        appointment.check_in_com_answered = true;

        newCommunication = {
          ...communication,
          agreed_interventions: agreed_interventions,
          agreements: checkin_agreements,
          remark,
          status: KIOSK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED,
          kiosk_name,
          kiosk_label_number,
          events: communication.events.concat({
            id: event_id,
            type: type,
            correct_phone,
            created_on,
          }),
        };

        break;

      case KIOSK_COMMUNICATION_EVENTS.LABEL_DETACHED:
        newCommunication = {
          ...communication,
          status: KIOSK_COMMUNICATION_EVENTS.LABEL_DETACHED,
          events: communication.events.concat({
            id: event_id,
            type: type,
            created_on,
            user,
          }),
        };

        break;

      default:
        return false;
    }

    if (appointment.kiosk_communications?.length) {
      appointment.kiosk_communications = appointment.kiosk_communications.filter(dc => dc.id !== newCommunication.id);
    }

    appointment.kiosk_communications = [...appointment.kiosk_communications, newCommunication];

    statusUpdated = updateAppointmentStatusIdentifier(appointment);
    return statusUpdated;
  } else if (updatePayload._topic === "KeycomUpdatedMessage") {
    if (!appointment.keylocker_communications) appointment.keylocker_communications = [];

    const { type, remark = null, keylocker_communication_id } = update;

    if (!keylocker_communication_id && remark) {
      appointment.keylocker_communications = appointment.keylocker_communications.map(comm =>
        comm.id === remark.keylocker_communication_id ? { ...comm, remark } : comm
      );
      return false;
    }

    if (!type || !keylocker_communication_id) return false;

    const communication = appointment.keylocker_communications?.find(kc => kc.id === keylocker_communication_id) || { events: [] };

    const { event_id } = update;

    let event = communication?.events.find(e => e.id === event_id);

    if (event) return false;

    const {
      user = null,
      customer = null,
      correct_phone = "",
      keylocker_box_number = "",
      keylocker_name = "",
      receivers = [],
      agreed_interventions = [],
      checkin_agreements = [],
      pin_request_type = null,
    } = update;
    const created_on = updatePayload._timestamp;

    let newCommunication = null;

    switch (type) {
      case KEYLOCKER_COMMUNICATION_EVENTS.DROP_PIN_SENT: {
        newCommunication = {
          id: keylocker_communication_id,
          status: KEYLOCKER_COMMUNICATION_STATUSES.DROP_PIN_SENT,
          user: user,
          customer: customer,
          created_on,
          events: [{ id: event_id, pin_request_type, receivers, type, created_on }],
        };

        break;
      }

      case KEYLOCKER_COMMUNICATION_EVENTS.CHECK_IN_OPENED: {
        newCommunication = {
          id: keylocker_communication_id,
          user: user,
          customer: customer,
          created_on,
          status: KEYLOCKER_COMMUNICATION_STATUSES.CHECK_IN_OPENED,
          ...communication,
          events: communication.events.concat({
            id: event_id,
            type: type,
            receivers,
            created_on,
          }),
        };

        break;
      }

      case KEYLOCKER_COMMUNICATION_EVENTS.CHECK_IN_ANSWERED: {
        newCommunication = {
          ...communication,
          status: KEYLOCKER_COMMUNICATION_STATUSES.CHECK_IN_ANSWERED,
          remark,
          agreements: checkin_agreements,
          agreed_interventions,
          events: communication.events.concat({
            id: event_id,
            type,
            keylocker_box_number,
            keylocker_name,
            correct_phone,
            receivers,
            created_on,
          }),
        };

        break;
      }

      case KEYLOCKER_COMMUNICATION_EVENTS.PICK_UP_PIN_SENT: {
        newCommunication = {
          ...communication,
          status: KEYLOCKER_COMMUNICATION_STATUSES.PICK_UP_PIN_SENT,
          agreed_interventions,
          events: communication.events.concat({
            id: event_id,
            type,
            keylocker_box_number,
            keylocker_name,
            receivers,
            created_on,
          }),
        };

        break;
      }

      default:
        return false;
    }

    if (appointment.keylocker_communications?.length) {
      appointment.keylocker_communications = appointment.keylocker_communications.filter(kc => kc.id !== newCommunication.id);
    }

    appointment.keylocker_communications = [...appointment.keylocker_communications, newCommunication];

    return false;
  } else if (updatePayload._topic === "InterventionCreatedMessage") {
    const interventionIdx = appointment.interventions.findIndex(i => i.id === update.id);
    if (interventionIdx === -1) appointment.interventions = [...appointment.interventions, update];
  } else if (updatePayload._topic === "InterventionUpdatedMessage") {
    appointment.interventions = appointment.interventions.map(intervention => {
      const idx = update.findIndex(u => u.id === intervention.id);
      if (idx > -1) return { ...intervention, ...update[idx] };
      else return intervention;
    });
  } else if (updatePayload._topic === "InterventionDeletedMessage") {
    appointment.interventions = appointment.interventions.filter(intervention => intervention.id !== update);
  } else if (updatePayload._topic === "AppointmentNoteCreatedMessage") {
    if (!appointment.notes) appointment.notes = [update];
    else appointment.notes = [...appointment.notes, update];
  } else if (updatePayload._topic === "AppointmentNoteUpdatedMessage") {
    appointment.notes = appointment.notes?.length
      ? appointment.notes.map(note => (note.id === update.id ? { ...note, ...update, attachments: update.attachments || null } : note))
      : null;
  } else if (updatePayload._topic === "AppointmentNoteDeletedMessage") {
    appointment.notes = appointment.notes?.length ? appointment.notes.filter(note => note.id !== update) : null;
  } else if (updatePayload._topic === "CheckPausedUpdatedMessage") {
    appointment.check_paused_at = update.check_paused_at;
  }
  return statusUpdated;
};

// Prepare car checks, ie. set the right order, assign items to groups
export const prepareChecks = checks => {
  const lists = [];
  let checkIndex = 0;

  // First sort by id to set the check index
  checks
    .sort((a, b) => a.id - b.id)
    .forEach(check => {
      check._check_index = ++checkIndex;
    });

  checks[checks.length - 1].lastCheck = true;

  // Sort the lists
  checks.sort((a, b) => {
    if (a.checklist.display_order < b.checklist.display_order) return -1;
    if (a.checklist.display_order > b.checklist.display_order) return 1;
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;

    return 0;
  });

  // Put the Extra Parts list on the top
  checks.forEach(c => {
    if (c.checklist.checklist_type !== CHECKLIST_TYPE.EXTRA_PART) {
      lists.push(c);
    } else {
      lists.unshift(c);
    }
  });

  for (let list of lists) {
    list.sublists = [];

    if (!list.question_items) continue;

    list.question_items.sort((a, b) => {
      if (a.group_order === b.group_order) {
        if (a.question_order < b.question_order) return -1;
        if (a.question_order > b.question_order) return 1;
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      } else {
        return a.group_order < b.group_order ? -1 : 1;
      }
    });

    for (let i = 0; i < list.question_items.length; i++) {
      let question = list.question_items[i];

      if (!list.sublists[question.group_order + "-" + question.group_name]) {
        list.sublists[question.group_order + "-" + question.group_name] = {
          items: [],
          group_name: question.group_name,
        };
      }

      question.parent_index = i;
      list.sublists[question.group_order + "-" + question.group_name].items.push(question);
    }
  }

  return lists;
};

export const createImagesByUrls = urls => {
  if (!urls || urls.length === 0) {
    return [];
  }

  let imgs = [];
  urls.forEach(url => {
    imgs.push({ url, active: true, intervention_index: 0, is_final_check: false, visible_in_pdf: true });
  });
  return imgs;
};

export const getUniqueCarMakes = (carMakes, oldCarMakes, FILTERS) => {
  if (!oldCarMakes) {
    oldCarMakes = [];
  }

  if (!FILTERS) {
    FILTERS = [];
  }

  if (carMakes) {
    let makeNames = [...new Set(carMakes.map(make => make))];
    return makeNames.map(function (make) {
      return {
        name: make,
        filter: oldCarMakes.findIndex(x => x.name === make && x.filter) > -1 || FILTERS.findIndex(f => f === make) > -1,
      };
    });
  }

  return [];
};

export const formatDateIfValid = (dateToFormat, formatInto = "DD-MM-YYYY") => {
  let formattedDate = "";

  if (dateToFormat && (moment.isMoment(dateToFormat) || !dateToFormat.startsWith("0001-01-01T00:00:00"))) {
    const md = moment(dateToFormat);
    formattedDate = md.format(formatInto);
  }

  return formattedDate;
};

export const getBrandFromStorage = () => {
  let list;
  const result = localStorage.getItem("brand");
  if (!isEmpty(result)) {
    try {
      list = JSON.parse(result);
    } catch (error) {}
  }
  return list || [];
};

export const addBrandToStorage = brand => {
  let list = getBrandFromStorage();
  list.push(brand);
  localStorage.setItem("brand", JSON.stringify(list));
};

export const removeBrandFromStorage = brand => {
  if (!isEmpty(brand)) {
    const list = getBrandFromStorage();
    const i = list.findIndex(x => x === brand);
    if (i !== -1) {
      list.splice(i, 1);
    }
    localStorage.setItem("brand", JSON.stringify(list));
  }
};

export const getStatusDetail = (identifier, statuses) => {
  if (!statuses) return {};

  let status = statuses.find(s => s.identifier === identifier);
  if (!status) status = {};

  return status;
};

export const getPriceForDisplay = price => {
  if (!price) {
    // eslint-disable-next-line
    return new Number(0).toFixed(2);
  }

  return parseFloat(price).toFixed(2);
};

export const processNotification = (appointment_wo, notification_element_ids, notification_entity_id, update, compare, notificationElements) => {
  const notification_elements = notificationElements.filter(
    el => notification_element_ids.includes(el.notification_element_id) && el.notification_entity_id === notification_entity_id
  );

  for (let i = 0; i < notification_elements.length; i++) {
    const element = notification_elements[i];

    if (typeof update === "number" && String(update) === element.field.value && update !== compare) {
      sendNotification(appointment_wo, element);
      break;
    }

    if (update instanceof Object && update[element.field.value] && update[element.field.value] !== compare[element.field.value]) {
      sendNotification(appointment_wo, element);
      break;
    }
  }
};

export const notifyUser = (appointment, notification_element_ids, notification_entity_id, update, compare, notificationElements) => {
  if (Notification.permission === "granted") processNotification(appointment, notification_element_ids, notification_entity_id, update, compare, notificationElements);
  else {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") processNotification(appointment, notification_element_ids, notification_entity_id, update, compare, notificationElements);
    });
  }
};

export const sendNotification = (appointment, notification_element) => {
  const {
    entity: { notification_entity_id },
    field: { name },
  } = notification_element;

  let content = "";
  let title = "";

  switch (notification_entity_id) {
    case NOTIFICATION_ENTITIES.NOTIFICATION_ENTITY_APPOINTMENT:
      title = `${appointment.wo_nr} ${i18n.t("appointment_updated").message || "Appointment updated"}`;
      content = `${i18n.t("appointment_updated").message || "Appointment updated"} ${i18n.t("to").message || "to"} ${i18n.t(name).message || name}`;
      break;
    case NOTIFICATION_ENTITIES.NOTIFICATION_ENTITY_STATUS_IDENTIFIER:
      title = `${appointment.wo_nr} ${i18n.t("status_updated").message || "Status updated"}`;
      content = `${i18n.t("status_updated").message || "Status updated"} ${i18n.t("to").message || "to"} ${i18n.t(name).message || name}`;
      break;
    default:
      break;
  }

  new Notification(title, { body: content, icon: "/static/favicon.ico" }).addEventListener("click", () =>
    window.open(`${document.location.origin}/#!/appointments/${appointment.id}`, "_blank")
  );
};

export const renderInterventionElementType = type => {
  switch (type) {
    case INTERVENTION_ELEMENT_TYPE.PART:
      return (
        <Label>
          <FontAwesomeIcon icon={faCog} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.LABOR:
      return (
        <Label>
          <FontAwesomeIcon icon={faUserHardHat} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.TEXT:
      return (
        <Label>
          <FontAwesomeIcon icon={faFileAlt} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.DISCOUNT:
      return (
        <Label>
          <FontAwesomeIcon icon={faPercentage} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.SMALL_PART:
      return (
        <Label>
          <FontAwesomeIcon icon={faCogs} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.FEE:
      return (
        <Label>
          <FontAwesomeIcon icon={faDollarSign} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.RENT:
      return (
        <Label>
          <FontAwesomeIcon icon={faKey} />
        </Label>
      );

    case INTERVENTION_ELEMENT_TYPE.MENU:
      return (
        <Label>
          <FontAwesomeIcon icon={faBars} />
        </Label>
      );

    default:
      return null;
  }
};
