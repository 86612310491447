export const PIN_TYPE = {
  REMARKS: 1,
  WARRANTY: 2,
  RECALL: 3,
};

export const PIN_STATUS = {
  NOT_SET: 0,
  DELETED: 1,
  SUPPORT: 2,
  APPROVAL_PENDING: 3,
  MORE_INFO: 4,
  APPROVED: 5,
  DECLINED: 6,
  TO_CLAIM: 7,
  CLAIMED: 8,
  BILLED: 9,
  REQUEST: 10,
  TECHNICAL_INFO: 11,
};

export const PIN_ORDER = {
  SAME_DAY: 1,
  TO_ORDER: 2,
  NEXT_DAY: 3,
  BACK_ORDER: 4,
};

export const WARRANTY_TYPE = {
  NOT_SET: 0,
  FACTORY: 1,
  LENIENCY: 2,
  PAINT_BODY: 3,
  ON_REPAIR: 4,
  OCCASION: 5,
  SERVICE: 6,
};
