import React, { Component } from "react";
import { Modal, Form, Dropdown, Checkbox, Button, Divider, TextArea, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { PasswordInput, UserInput } from "../../../../components";
import { Can } from "../../../";
import { DMS } from "../../util";

const dmsLocation = {
  dealer_location_dms_id: null,
  dealer_id: null,
  dms_id: null,
  comment: "",
  dms_location_id: "",
  database: "",
  maintenance_intervention: "",
  customer_not_approved: "",
  not_visible_to_customer: "",
  exclude_from_import: "",
  incadea_config: null,
  autoline_config: null,
};

const incadeaConfig = {
  dms_dealer_id: "",
  dms_appointment_type: "",
  dms_warranty_intervention_id: "",
  dms_internal_intervention_id: "",
};

const autolineConfig = {
  business_customer_table_id: "",
  customer_table_id: "",
  car_table_id: "",
  customer_detail_table_id: "",
  car_make_table_id: "",
  car_note_table_id: "",
  department_id: "",
  vat_table_id: "",
  price_includes_vat: "",
  rental_billed: "",
  rental_code: "",
  rental_status: "",
};

const dealerLocation = {
  dealer_location_id: null,
  dsn: "",
  dms_write_back: false,
  dms_warranty_pin: false,
  keep_importing_after_work_started: false,
  is_keyloop_event_enabled: false,
  autoflex_username: "",
  autoflex_password: "",
  automatic_appointment_refresh_interval: null,
  dealer_location_dms: [{ ...dmsLocation }],
};

class LocationDetail extends Component {
  state = {
    locationConfiguration: this.props.selectedLocation ? { ...this.props.selectedLocation, dealer_location_id: this.props.selectedLocation.id } : dealerLocation,
    overrideDMSLocations: [],
  };

  componentDidMount() {
    const { dealer_location_dms } = this.state.locationConfiguration;

    const overrideDMSLocations = dealer_location_dms
      ?.filter(location => location.autoline_config || location.incadea_config)
      .map(location => location.dealer_location_dms_id);

    if (overrideDMSLocations?.length) this.setState({ overrideDMSLocations });
  }

  isSchedulerDMS = dms => {
    return [DMS.KEYLOOP_JOBS, DMS.KEYLOOP_MENUS, DMS.NEXTLANE, DMS.AUTOFLEX, DMS.AUTOFLEX_QA_STAGING, DMS.TEST_API].includes(dms);
  };

  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState(({ locationConfiguration }) => ({
      locationConfiguration: { ...locationConfiguration, [name]: value },
    }));
  };

  handleNumberChange = (_e, { name, value }) => {
    if (isNaN(value)) return;

    this.setState(({ locationConfiguration }) => ({
      locationConfiguration: { ...locationConfiguration, [name]: Number(value) },
    }));
  };

  handleChangeDropdown = (_e, { name, value }) => {
    this.setState(({ locationConfiguration }) => ({
      locationConfiguration: { ...locationConfiguration, [name]: value },
    }));
  };

  handleCheckboxChange = (_, { name, checked }) => {
    this.setState(({ locationConfiguration }) => ({ locationConfiguration: { ...locationConfiguration, [name]: checked } }));
  };

  handleDMSLocationInputChange = ({ target: { name, value } }, dms_location) => {
    const { locationConfiguration } = this.state;

    const newDmsLocations = locationConfiguration.dealer_location_dms.map(i => {
      if (i.dealer_location_dms_id === dms_location.dealer_location_dms_id) return { ...i, [name]: value };
      return i;
    });

    this.setState({ locationConfiguration: { ...locationConfiguration, dealer_location_dms: newDmsLocations } });
  };

  handleAddDMSLocation = () => {
    const { locationConfiguration } = this.state;
    const { dealer_location_dms } = locationConfiguration;

    if (dealer_location_dms?.some(location => !location.dealer_location_dms_id)) return;

    const newDmsLocations = !dealer_location_dms ? [{ ...dmsLocation }] : dealer_location_dms?.concat({ ...dmsLocation });

    this.setState({ locationConfiguration: { ...locationConfiguration, dealer_location_dms: newDmsLocations } });
  };

  handleRemoveDMSLocation = dealer_location_dms_id => {
    const { dealer_location_dms } = this.state.locationConfiguration;

    const newDmsLocations = dealer_location_dms.filter(i => i.dealer_location_dms_id !== dealer_location_dms_id);

    this.setState({ locationConfiguration: { ...this.state.locationConfiguration, dealer_location_dms: newDmsLocations } });
  };

  handleSubfieldInputChange = ({ name, value, subfield }, location) => {
    const { locationConfiguration } = this.state;
    const { dealer_location_dms } = locationConfiguration;

    const newLocation = { ...location, [subfield]: { ...location[subfield], [name]: value } };
    const newDmsLocations = dealer_location_dms?.map(loc => (loc.dealer_location_dms_id === location.dealer_location_dms_id ? newLocation : loc));

    this.setState({
      locationConfiguration: {
        ...locationConfiguration,
        dealer_location_dms: newDmsLocations,
      },
    });
  };

  handleChangeDMSSpecificToggle = location => {
    const { locationConfiguration, overrideDMSLocations } = this.state;

    const newOverrideDMSLocations = overrideDMSLocations.includes(location.dealer_location_dms_id)
      ? overrideDMSLocations.filter(l => l !== location.dealer_location_dms_id)
      : [...overrideDMSLocations, location.dealer_location_dms_id];

    const newDmsLocations = locationConfiguration.dealer_location_dms?.map(l => {
      if (l.dealer_location_dms_id !== location.dealer_location_dms_id) return l;
      if (!newOverrideDMSLocations.includes(location.dealer_location_dms_id)) return { ...l, autoline_config: null, incadea_config: null };
      if (!l.autoline_config && locationConfiguration.dms_id === DMS.AUTOLINE) return { ...l, autoline_config: { ...autolineConfig } };
      if (!l.incadea_config && locationConfiguration.dms_id === DMS.INCADEA) return { ...l, incadea_config: { ...incadeaConfig } };
      return l;
    });

    this.setState({ overrideDMSLocations: newOverrideDMSLocations, locationConfiguration: { ...locationConfiguration, dealer_location_dms: newDmsLocations } });
  };

  renderDMSConfig = location => {
    if (!this.state.overrideDMSLocations.includes(location.dealer_location_dms_id)) return;

    const { dms_id, incadea_config, autoline_config } = location;
    const { t } = this.props;

    switch (dms_id) {
      case DMS.INCADEA:
        return (
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("dms_dealer_id").message || "DMS Dealer ID"}</label>
              <UserInput
                value={incadea_config.dms_dealer_id}
                subfield="incadea_config"
                onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                name="dms_dealer_id"
              />
            </Form.Field>

            <Form.Field>
              <label>{t("dms_appointment_type").message || "DMS Appointment Type"}</label>
              <UserInput
                value={incadea_config.dms_appointment_type}
                subfield="incadea_config"
                onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                name="dms_appointment_type"
              />
            </Form.Field>

            <Form.Field>
              <label>{t("dms_warranty_intervention_id").message || "DMS Warranty Intervention ID"}</label>
              <UserInput
                value={incadea_config.dms_warranty_intervention_id}
                subfield="incadea_config"
                onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                name="dms_warranty_intervention_id"
              />
            </Form.Field>

            <Form.Field>
              <label>{t("dms_internal_intervention_id").message || "DMS Internal Intervention ID"}</label>
              <UserInput
                value={incadea_config.dms_internal_intervention_id}
                subfield="incadea_config"
                onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                name="dms_internal_intervention_id"
              />
            </Form.Field>
          </Form.Group>
        );

      case DMS.AUTOLINE:
        return (
          <>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("business_customer_table_id").message || "Business customer table ID"}</label>
                <UserInput
                  value={autoline_config.business_customer_table_id}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="business_customer_table_id"
                />
              </Form.Field>

              <Form.Field>
                <label>{t("customer_table_id").message || "Customer table ID"}</label>
                <UserInput
                  value={autoline_config.customer_table_id}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="customer_table_id"
                />
              </Form.Field>

              <Form.Field>
                <label>{t("car_table_id").message || "Car table ID"}</label>
                <UserInput
                  value={autoline_config.car_table_id}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="car_table_id"
                />
              </Form.Field>

              <Form.Field>
                <label>{t("customer_detail_table_id").message || "Customer detail table ID"}</label>
                <UserInput
                  value={autoline_config.customer_detail_table_id}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="customer_detail_table_id"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field width={3}>
                <label>{t("car_make_table_id").message || "Car make table ID"}</label>
                <UserInput
                  value={autoline_config.car_make_table_id}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="car_make_table_id"
                />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("car_note_table_id").message || "Car note table ID"}</label>
                <UserInput
                  value={autoline_config.car_note_table_id}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="car_note_table_id"
                />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("department_id").message || "Department ID"}</label>
                <UserInput
                  value={autoline_config.department_id}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="department_id"
                />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("vat_table_id").message || "VAT Table ID"}</label>
                <UserInput
                  value={autoline_config.vat_table_id}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="vat_table_id"
                />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("price_includes_vat").message || "Price includes VAT"}</label>
                <UserInput
                  value={autoline_config.price_includes_vat}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="price_includes_vat"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("rental_billed").message || "Rental billed"}</label>
                <UserInput
                  value={autoline_config.rental_billed}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="rental_billed"
                />
              </Form.Field>

              <Form.Field>
                <label>{t("rental_code").message || "Rental code"}</label>
                <UserInput
                  value={autoline_config.rental_code}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="rental_code"
                />
              </Form.Field>

              <Form.Field>
                <label>{t("rental_status").message || "Rental status"}</label>
                <UserInput
                  value={autoline_config.rental_status}
                  subfield="autoline_config"
                  onChange={(_e, data) => this.handleSubfieldInputChange(data, location)}
                  name="rental_status"
                />
              </Form.Field>
            </Form.Group>
          </>
        );

      default:
        return null;
    }
  };

  render() {
    const { locationConfiguration, overrideDMSLocations } = this.state;
    const {
      dealer_location_id,
      dsn,
      dms_write_back,
      dms_warranty_pin,
      keep_importing_after_work_started,
      automatic_appointment_refresh_interval,
      is_keyloop_event_enabled,
      autoflex_username,
      autoflex_password,
      has_autoflex_password,
      dealer_location_dms,
    } = locationConfiguration;
    const { t, isEditingLocation, locationOptions, handleToggleAddLocation, handleToggleEditLocation, handleAddOrUpdateLocation, handleDeleteLocation } = this.props;

    return (
      <Modal open={true} closeOnDimmerClick={false}>
        <Modal.Header>
          <div style={{ display: "inline-block" }}>{isEditingLocation ? t("edit_location").message || "Edit Location" : t("add_location").message || "Add Location"}</div>
        </Modal.Header>

        <Modal.Content scrolling>
          <Form>
            <Form.Group>
              <Form.Field className="full-width-input">
                <label>{t("select_location").message || "Select Location"}</label>
                <Dropdown
                  options={locationOptions}
                  value={dealer_location_id}
                  name="dealer_location_id"
                  onChange={this.handleChangeDropdown}
                  selection
                  selectOnBlur={false}
                  clearable
                  disabled={isEditingLocation}
                  search
                />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("enter_dsn").message || "Enter DSN"}</label>
                <UserInput value={dsn} onChange={this.handleInputChange} name="dsn" />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={8}>
                <label>{t("automatic_appointment_refresh_interval").message || "Automatic appointment refresh interval"}</label>
                <UserInput
                  type="number"
                  value={automatic_appointment_refresh_interval}
                  onChange={this.handleNumberChange}
                  name="automatic_appointment_refresh_interval"
                />
              </Form.Field>
            </Form.Group>

            {locationConfiguration.dms_id === DMS.AUTOFLEX && (
              <Form.Group widths="equal">
                <Form.Field>
                  <label>{t("autoflex_username").message || "Autoflex username"}</label>
                  <UserInput value={autoflex_username} onChange={this.handleInputChange} name="autoflex_username" />
                </Form.Field>

                <Form.Field>
                  <label>{t("autoflex_password").message || "Autoflex password"}</label>
                  <PasswordInput
                    type="password"
                    value={autoflex_password}
                    onChange={this.handleInputChange}
                    name="autoflex_password"
                    showAsteriskPlaceholder={has_autoflex_password}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group>
              <Form.Field>
                <label>{t("dms_write_back").message || "DMS Write Back"}</label>
                <Checkbox toggle name="dms_write_back" checked={dms_write_back} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field>
                <label>{t("set_warranty_pin_from_dms").message || "Set Warranty Pin From DMS"}</label>
                <Checkbox toggle name="dms_warranty_pin" checked={dms_warranty_pin} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field>
                <label>{t("keyloop_event").message || "Keyloop event"}</label>
                <Checkbox toggle name="is_keyloop_event_enabled" checked={is_keyloop_event_enabled} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field>
                <label>{t("keep_importing_after_work_started").message || "Keep Importing After Work Started"}</label>
                <Checkbox toggle name="keep_importing_after_work_started" checked={keep_importing_after_work_started} onChange={this.handleCheckboxChange} />
              </Form.Field>
            </Form.Group>

            {!this.isSchedulerDMS(locationConfiguration.dms_id) && !dealer_location_dms?.some(location => !location.dealer_location_dms_id) && (
              <div className="AddDMSLocationButton-container">
                <Button color="green" onClick={this.handleAddDMSLocation}>
                  {t("add_dms_location_id").message || "Add DMS Location ID"}
                </Button>
              </div>
            )}

            {dealer_location_dms?.map(location => (
              <>
                <Divider />
                <Form.Group widths="equal">
                  <Form.Field width={7}>
                    <label>{t("enter_dms_location_id").message || "Enter DMS Location ID"}</label>
                    <UserInput value={location.dms_location_id} onChange={e => this.handleDMSLocationInputChange(e, location)} name="dms_location_id" />
                  </Form.Field>

                  <Form.Field width={8}>
                    <label>{t("enter_database").message || "Enter Database"}</label>
                    <UserInput value={location.database} onChange={e => this.handleDMSLocationInputChange(e, location)} name="database" />
                  </Form.Field>

                  {dealer_location_dms.length > 1 && (
                    <Form.Field className="DeleteDMSLocation-container" width={1}>
                      <Button icon color="red" onClick={() => this.handleRemoveDMSLocation(location.dealer_location_dms_id)}>
                        <Icon name="trash" />
                      </Button>
                    </Form.Field>
                  )}
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <label>{t("customer_not_approved").message || "Customer not approved"}</label>
                    <UserInput value={location.customer_not_approved} onChange={e => this.handleDMSLocationInputChange(e, location)} name="customer_not_approved" />
                  </Form.Field>

                  <Form.Field>
                    <label>{t("maintenance_intervention").message || "Maintenance intervention"}</label>
                    <UserInput value={location.maintenance_intervention} onChange={e => this.handleDMSLocationInputChange(e, location)} name="maintenance_intervention" />
                  </Form.Field>

                  <Form.Field>
                    <label>{t("not_visible_to_customer").message || "Not visible to customer"}</label>
                    <UserInput value={location.not_visible_to_customer} onChange={e => this.handleDMSLocationInputChange(e, location)} name="not_visible_to_customer" />
                  </Form.Field>

                  <Form.Field>
                    <label>{t("exclude_from_import").message || "Exclude from import"}</label>
                    <UserInput value={location.exclude_from_import} onChange={e => this.handleDMSLocationInputChange(e, location)} name="exclude_from_import" />
                  </Form.Field>
                </Form.Group>

                <Form.Group>
                  <Form.Field className="textarea-comment">
                    <label>{t("comment").message || "Comment"}</label>
                    <TextArea value={location.comment} onChange={e => this.handleDMSLocationInputChange(e, location)} name="comment" />
                  </Form.Field>
                </Form.Group>

                {location.dealer_location_dms_id && [DMS.INCADEA, DMS.AUTOLINE].includes(location.dms_id) && (
                  <Form.Field>
                    <label>{t("override_dms_specific_values").message || "Override DMS Specific values"}</label>
                    <Checkbox
                      toggle
                      onChange={() => this.handleChangeDMSSpecificToggle(location)}
                      checked={overrideDMSLocations.includes(location.dealer_location_dms_id)}
                    />
                  </Form.Field>
                )}

                {this.renderDMSConfig(location)}
              </>
            ))}
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button color="grey" onClick={isEditingLocation ? () => handleToggleEditLocation(null) : e => handleToggleAddLocation(e, null)}>
            {t("cancel").message || "Cancel"}
          </Button>
          <Can I="update-location" the="dmsv3-setting">
            <Button color="green" onClick={() => handleAddOrUpdateLocation(locationConfiguration)}>
              {t("save").message || "Save"}
            </Button>

            {isEditingLocation && (
              <Button color="red" floated="left" onClick={handleDeleteLocation}>
                {t("delete").message || "Delete"}
              </Button>
            )}
          </Can>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(LocationDetail);
