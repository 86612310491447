import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons";

import ENV from "../../util/env-config";
import { DESK_COMMUNICATION_EVENTS } from "./common";
import Service from "./service";

class DeskCheckinModal extends Component {
  state = {
    isLoading: true,
    statusName: "loading",
    cancelToken: null,
    showRetryButton: false,
    desk_communication_id: null,
    receptionist_key: null,
    isCheckinOut: false,
  };

  componentDidMount() {
    this.handleRetry();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.desk_communications === this.props.desk_communications) return;

    if (!this.props.desk_communications?.length) return;

    const comm = [...this.props.desk_communications].sort((dc1, dc2) => dc2.id - dc1.id)[0];

    if (!comm.events?.length) return;

    const event = [...comm.events].sort((e1, e2) => e2.id - e1.id)[0];
    let prevEvent = null;

    if (prevProps.desk_communications?.length && prevProps.desk_communications[0].events?.length) {
      prevProps.desk_communications.sort((dc1, dc2) => dc2.id - dc1.id);
      prevEvent = prevProps.desk_communications[0].events.sort((e1, e2) => e2.id - e1.id)[0];
    }

    if (prevEvent?.id !== event.id) {
      switch (event.type) {
        case DESK_COMMUNICATION_EVENTS.CHECKIN_SENT:
          this.setState({ statusName: comm.is_checking_out ? "desk_check_out_sending" : "desk_checkin_sending" });
          break;
        case DESK_COMMUNICATION_EVENTS.CHECKIN_RECEIVED:
          this.setState({ statusName: comm.is_checking_out ? "desk_check_out_opening" : "desk_checkin_opening" });
          break;
        case DESK_COMMUNICATION_EVENTS.CHECKIN_FAILED_TO_OPEN:
          this.setState({ statusName: comm.is_checking_out ? "desk_check_out_failed_to_open" : "desk_checkin_failed_to_open", isLoading: false, showRetryButton: true });
          break;
        case DESK_COMMUNICATION_EVENTS.CHECKIN_OPENED:
          this.setState({ statusName: "waiting_for_answer" });
          break;
        case DESK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED:
          this.setState({ statusName: "desk_checkin_answered", isLoading: false });
          break;
        case DESK_COMMUNICATION_EVENTS.CHECKIN_CLOSED:
          this.setState({ statusName: comm.is_checking_out ? "desk_check_out_closed" : "desk_checkin_closed", isLoading: false });
          break;
        default:
          this.setState({ statusName: "unknown_error_occured", isLoading: false });
          break;
      }

      this.setState({ isCheckinOut: comm.is_checking_out });
    }
  }

  handleCancel = () => {
    const { cancelToken, desk_communication_id } = this.state;

    if (cancelToken) cancelToken.cancel();

    Service.cancelDeskCheckin(desk_communication_id)
      .then(result => {
        this.props.onClose();
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleClose = () => {
    const { appointment, onClose } = this.props;
    const { isCheckinOut, statusName } = this.state;

    onClose();

    if (!isCheckinOut && (!appointment.car_in_shop || appointment.car_out_of_shop) && statusName === "desk_checkin_answered") {
      document.getElementById("car-in-shop-step").click();
    }
  };

  handleRetry = () => {
    let { request, token } = Service.createDeskCheckin({ appointment_id: this.props.appointment.id, questions: this.props.selectedDeskComm });

    this.setState({ cancelToken: token, isLoading: true, desk_communication_id: null, receptionist_key: null }, () => {
      request
        .then(result => {
          if (result?.data?.data) {
            const { id, receptionist_key } = result.data.data;

            this.setState({ desk_communication_id: id, receptionist_key });
          } else this.setState({ showRetryButton: true, isLoading: false, statusName: "unknown_error_occured" });
        })
        .catch(err => {
          console.log(err);
          this.setState({ showRetryButton: true, isLoading: false, statusName: "unknown_error_occured" });
        });
    });
  };

  handleOpenDesckCheckin = () => {
    window.open(`${ENV.ccBaseUrl}/deskcheckin/#${this.state.receptionist_key}`, "_blank");
  };

  render() {
    const { t } = this.props;
    const { statusName, isLoading, showRetryButton, receptionist_key } = this.state;

    return (
      <Modal open={true} closeOnDimmerClick={false} size="mini">
        {receptionist_key && (
          <Modal.Header style={{ textAlign: "right" }}>
            <Button size="large" basic onClick={this.handleOpenDesckCheckin}>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Button>
          </Modal.Header>
        )}
        <Modal.Content>
          {isLoading && (
            <div className="Checks-Placeholder Loader-Placeholder">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )}
          <h3 style={{ textAlign: "center" }}>{t(statusName).message || statusName}...</h3>
        </Modal.Content>

        <Modal.Actions>
          {showRetryButton && (
            <Button color="green" onClick={this.handleRetry}>
              {t("retry").message || "Retry"}
            </Button>
          )}
          {isLoading ? (
            <Button color="red" onClick={this.handleCancel}>
              {t("cancel").message || "Cancel"}
            </Button>
          ) : (
            <Button color="red" onClick={this.handleClose}>
              {t("close").message || "Close"}
            </Button>
          )}
          <br />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(DeskCheckinModal);
